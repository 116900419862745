/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*スクロールバー全体*/
::-webkit-scrollbar {
  width: 10px;
}

/*スクロールバーの軌道*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-contexify {
  transform-origin: top left;
  transform: scale(0.7);
}

input:disabled {
  opacity: 0.3;
}

table.full-width {
  max-width: 100%;
}

html {
  font-size: 14px !important;
}

body, body * {
  font-family: "Helvetica Neue",
  Arial,
  "Hiragino Kaku Gothic ProN",
  "Hiragino Sans",
  Meiryo,
  sans-serif !important;
}